/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Getter, Mutation, Action, State} from 'vuex-class'
import { VideoStories } from '@/store/videoStories/types';

const namespace: string = 'videoStories';

export default class VideoStoriesModelChoicePage extends Vue {
	@State('videoStories') videoStories: VideoStories | undefined;
	@Getter('getModelLists', {namespace}) getModelLists : any;
	@Action('fetchVideoStoriesModels', {namespace}) fetchVideoStoriesModels: any;
	@Mutation('setChosenModels', {namespace}) setChosenModels: any;

	modelChosen(model: Object) {
		this.setChosenModels(model);
		this.$router.push({ name: 'video-stories-video-choice' }).then(r => r);
	}

	created() {
		this.fetchVideoStoriesModels();
	}
}
